import React from 'react';
import PropTypes from 'prop-types';

import { graphql } from 'gatsby';

import SEO from '../SEO';
import RecipeCard from '../recipe/RecipeCard';

const CategoryPage = (props) => {
    const { data = { category, recipes } } = props;
    const { title, posts } = data.category || {};
    const pagepathname = props.location.pathname;

    return (
        <>
            <SEO
                title={title}
                pagepathname={pagepathname}
                description={'A DESCRIPTION'}
            />

            <h1 className="u-title">
                {title}
            </h1>

            {!data.category && <p>No recipes in this category yet!</p>}

            {posts &&
                <div className="recipes">
                    {posts.map(recipe => (
                        <RecipeCard
                            key={`latest-${recipe.id}`}
                            title={recipe.title}
                            id={recipe.id}
                            image={recipe.image}
                            slug={recipe.slug}
                            basicinfo={recipe.basicinfo}
                            categories={recipe.categories}
                            ratings={recipe.ratings}
                            cuisine={recipe.cuisine}
                            recipetype={recipe.recipetype}
                            author={recipe.author}
                        />
                    ))}
                </div>
            }
        </>
    );
};

export default CategoryPage;

export const query = graphql`
    query CategoryTemplateQuery($id: String!) {
        category: sanityCategory(id: {eq: $id}) {
          title
          posts {
                id
                image {
                    _rawAsset(resolveReferences: {maxDepth: 10})
                }
                slug {
                    current
                }
                title
                categories {
                    id
                    title
                    slug {
                        current
                    }
                }
                basicinfo {
                    preptime
                    cooktime
                    cleantime
                }
                ratings {
                    taste
                    ease
                    cost
                    portion
                }
                cuisine
                recipetype
                author
                tags {
                    value
                    label
                }
            }
        }
    }
`;

CategoryPage.propTypes = {
    data: PropTypes.object,
    location: PropTypes.object,
};
